<template>
  <page-container class="user-info-container">
    <van-nav-bar title="实名认证" left-arrow @click-left="$router.back()">
    </van-nav-bar>
    <van-form @submit="onSubmit" label-width="8.5em" input-align="right">
      <div class="user-info-card-item">
        <van-field
          v-model.trim="form.name"
          :readonly="canBeEditByStatus"
          :right-icon="renderRightIcon('name')"
          name="name"
          required
          label="真实姓名"
          placeholder="真实姓名"
          :rules="[{ required: true, message: '请输入真实姓名' }]"
        />
        <van-field
          v-model.trim="form.idNum"
          :type="inputType"
          :readonly="canBeEditByStatus"
          :right-icon="renderRightIcon('idNum')"
          name="idNum"
          required
          label="身份证号"
          placeholder="身份证号"
          :rules="[{ required: true, message: '请输入身份证号' }]"
        >
        </van-field>
        <van-field
          v-model.trim="form.mobile"
          :type="inputType"
          :readonly="canBeEditByStatus"
          :right-icon="renderRightIcon('mobile')"
          name="mobile"
          required
          label="手机号码"
          placeholder="手机号码"
          :rules="[
            { required: true, message: '请输入手机号码' },
            {
              pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号',
            },
          ]"
        />

        <!-- <van-field
          label="身份证人像面"
          name="idPic2"
          :readonly="!canBeEditByStatus"
          :rules="[{ required: false, message: '请选择身份证人像面' }]"
        >
          <template #input>
            <file-upload v-model="form.idPic2" :disabled="!canBeEditByStatus" />
          </template>
        </van-field> -->

        <!-- <van-field
          label="身份证国徽面"
          name="idPic1"
          :readonly="!canBeEditByStatus"
          :rules="[{ required: false, message: '请选择身份证国徽面' }]"
        >
          <template #input>
            <file-upload v-model="form.idPic1" :disabled="!canBeEditByStatus" />
          </template>
        </van-field> -->
      </div>

      <div class="submit-container">
        <div v-if="userInfo.isAuthentication === 1" class="show-container">
          已实名认证
        </div>
        <van-button
          v-if="userInfo.isAuthentication === 0"
          :loading="saving"
          round
          block
          type="primary"
          native-type="submit"
          :disabled="saving"
        >
          <span>保存</span>
          <!-- <van-count-down
            v-if="reSubmitCountDownTime"
            style="display: inline-block"
            :time="reSubmitCountDownTime"
            format="ss"
            auto-start
            @finish="reSubmitCountDownTime = 0"
          >
            <template #default="timeData">
              请等待{{ timeData.seconds }}秒
            </template>
          </van-count-down> -->
        </van-button>
      </div>
    </van-form>
  </page-container>
</template>

<script>
import { Dialog } from "vant";
import { getUserInfo, userAuthentication, saveBetween } from "@/api/user";
import { getBankList } from "@/api/user";
import BankAddressSelector from "@/components/BankAddressSelector";
import { Toast } from "vant";
import { contractList } from "@/api/normalGoods";
import FileUpload from "@/components/FileUpload.vue";

export default {
  name: "userAuthentication",
  components: { FileUpload, BankAddressSelector },
  data() {
    return {
      iframeUrl: "",
      userInfo: {},
      initInfo: null,
      bankList: [],
      showPicker: false,
      inputType: "text",
      show: true,
      form: {
        name: "", // 真实姓名
        mobile: "", // 手机号码
        idNum: "", // 身份证号
        idPic2: "", // 身份证人像面
        idPic1: "", // 身份证国徽面
      },
      saving: false,
      reSubmitCountDownTime: 0,
    };
  },
  computed: {
    canBeEditByStatus() {
      return false;
      // return this.userInfo.isAuthentication === 1;
      // const { status } = this.userInfo;
      // return status !== '02';
    },
    bankAddressInfo: {
      get() {
        let { bankProvince, bankProvinceName, bankCity, bankCityName } =
          this.form;
        return [
          { value: bankProvince, title: bankProvinceName },
          { value: bankCity, title: bankCityName },
        ];
      },
      set(val) {
        let [
          { value: bankProvince = "", title: bankProvinceName = "" },
          { value: bankCity = "", title: bankCityName = "" },
        ] = Array.isArray(val) ? val : [];
        Object.assign(this.form, {
          bankProvince,
          bankProvinceName,
          bankCity,
          bankCityName,
        });
      },
    },
  },
  async created() {
    let countDown =
      window.localStorage.getItem("userInfoReSubmitCountDown") || 0;
    if (countDown && Date.now() < countDown) {
      this.reSubmitCountDownTime = +countDown - Date.now();
    }
    this.init()
   
  },
  methods: {
    async init(){
       await getUserInfo().then((res) => {
      this.userInfo = res || {};
      this.form = [
        {},
        "name",
        "mobile",
        "idNum",
        "idPic2", // 身份证人像面
        "idPic1", // 身份证国徽面
      ].reduce((obj, key) => {
        obj[key] = this.userInfo[key] || "";
        return obj;
      });
      this.initInfo = { ...this.form };
    });
    getBankList().then((res) => {
      this.bankList = res || [];
      let bankName = "";
      for (let bank of this.bankList) {
        if (bank.value === this.userInfo.bank) {
          bankName = bank.label;
          break;
        }
      }
      this.form.bankName = bankName;
    });
    },
    canBeEdit(key) {
      let { initInfo } = this;
      return initInfo && !initInfo[key];
    },
    renderRightIcon(key) {
      // return this.canBeEdit(key) ? 'edit' : '';
      return this.canBeEditByStatus ? "edit" : "";
    },
    formatLevelName(level) {
      return {
        1: "普通用户",
        2: "居间人",
        3: "推广员",
        4: "店铺",
      }[level];
    },
    onSubmit() {
      this.saving = true;
      userAuthentication(
        { ...this.form },
        { headers: { getAllResponse: true } }
      ).then((res) => {
        if (res.data.code == 0) {
          window.localStorage.setItem(
            "userInfoReSubmitCountDown",
            Date.now() + 30 * 1000
          );
          this.reSubmitCountDownTime = 30 * 1000;
          Toast('实名认证保存成功');
          this.init()
          // this.$router.back();
        } else {
          Toast(res.data.msg);
        }
        this.saving = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user-info-container {
  .user-info-card-item {
    margin: 10px;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
  }

  .user-info-avatar {
    /deep/ .van-field__label {
      display: flex;
      align-items: center;
    }

    .van-image {
      width: 40px;
      height: 40px;
    }
  }

  .show-container {
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    text-align: right;
    font-size: 14px;
    color: #666;

    & > span {
      margin-right: 10px;
    }
  }

  .submit-container {
    padding: 10px 20px 20px 20px;
  }
}

::v-deep .van-nav-bar {
  background-color: #f8f8f8;

  .van-icon {
    color: #212121;
    font-size: 18px;
  }
}

.mask {
  z-index: 998;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.a1 {
  z-index: 999;
  border-radius: 3%;
  background-color: #faf8f8;
  border: 1px solid rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  right: 23px;
  z-index: 9999;
  width: 330px;
  height: 150px;
}

.a1-1 {
  color: rgb(88, 88, 244);
  // text-align: center;
  // margin-top: 15px;
}

.a1-2 {
  margin-left: 10px;
  margin-top: 28px;
}

.a1-3 {
  text-align: center;
  margin-top: 13px;
}

.a2 {
  margin-top: 8px;

  display: flex;
}

.a2-1 {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  line-height: 30px;
  width: 165px;
  height: 30px;
  text-align: center;
}
</style>

