<template>
  <van-uploader
      v-model="fileList"
      :accept="accept"
      :max-count="maxCount"
      :max-size="maxSizeM"
      :disabled="disabled"
      :deletable="!disabled"
      @oversize="onOversize"
      :after-read="_afterRead"
      :before-read="_beforeRead"
      :preview-options="{
        images: base64Images,
      }"
  />
</template>

<script>
import { Toast } from 'vant';
import { uploadImg } from '@/api/sessionMessage';
import compressor from '@/util/compressorJs';

const DEFAULT_MAX_SIZE = 5;

export default {
  name: 'FileUpload',
  props: {
    value: {
      default: () => '',
    },
    accept: {
      default: () => '.jpeg,.jpg,.gif,.png',
    },
    maxCount: {
      default: () => 1,
    },
    beforeRead: {
      type: Function,
      default: () => () => true,
    },
    disabled: {
      default: () => false,
    },
    maxSize: {
      default: () => DEFAULT_MAX_SIZE,
    }
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    base64Images() {
      return this.fileList.map(e => e.content || e.url);
    },
    maxSizeM() {
      let maxSize = +this.maxSize;
      maxSize = maxSize && maxSize > 0 ? maxSize : DEFAULT_MAX_SIZE;

      return maxSize * 1024 * 1024;
    },
  },
  mounted() {
    this.$watch(() => [this.modelValue], () => {
      let modelValue = this.modelValue;
      if (modelValue && !this.fileList.length) {
        if (typeof modelValue === 'string') {
          this.fileList = modelValue.split(',').map(url => ({
            url,
            status: 'done',
          }));
        } else if (Array.isArray(modelValue)) {
          this.fileList = modelValue.map(url => ({
            url,
            status: 'done',
          }));
        }
      }
    }, { deep: true, immediate: true });

    this.$watch(() => [this.fileList], () => {
      let modelValue = this.modelValue;
      let val = this.fileList.map(e => e.url);
      if (typeof modelValue === 'string')
        val = val.join(',');

      this.modelValue = val;
      // this.$emit('update:modelValue', val);
    }, { deep: true });

  },
  methods: {
    onOversize() {
      Toast(`文件大小不能超过 ${this.maxSize}m`);
    },
    _beforeRead(file) {
      const fn = this.beforeRead;
      if (typeof fn === 'function') {
        return fn(file);
      }
      return true;
    },
    async _afterRead(upload) {
      upload.status = 'uploading';
      upload.message = '上传中...';

      let formData = new FormData();
      const file = await compressor(upload.file);
      formData.append('file', file);
      uploadImg(formData).then(res => {
        if (res) {
          upload.status = 'done';
          upload.message = '上传成功';
          upload.url = res.url;

        } else {
          this.fileList.splice(this.fileList.indexOf(upload), 1);
        }
      });
    },
  },
};
</script>
